import { Button, Col, Row, Form, Input, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./index.less";
const { Search } = Input;

// It's shows the card catalog info
class index extends Component {
  state = {
    editCouncilModal: false,
    // addCardModal: false,
    selectedCouncilData: null,
    selectedCouncilId: null,
    selectedCouncil: null,
    councilInfoModal: false,
  };

  componentDidMount() {
    this.props.loadCouncilTaxes(null);
  }

  // Open the edit card pop up
  handleEditCouncilInfo = (data) => {
    const { editCouncilModal } = this.state;

    this.setState({
      selectedCouncilData: data || null,
      selectedCouncilId: data?.app_council_information_id || null,
      editCouncilModal: !editCouncilModal,
    });
  };

  submitForm = (values) => {
    console.log("values", values);

    let passObj = { ...values, id: this.state.selectedCouncilId };

    this.props.updateCouncilInfo(passObj).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({
          editCouncilModal: false,
          selectedCouncilData: null,
        });
        notifySuccess(res?.data?.message || "Done");
        this.props.loadCouncilTaxes(null);
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  onSearch = (value) => {
    this.props.loadCouncilTaxes(value);
  };

  // Render the card info in table formate
  columns = [
    {
      title: "ID",
      dataIndex: "app_council_information_id",
      key: "app_council_information_id",
      render: (_text, record) => (
        <p className="brand-name">{record.app_council_information_id || ""}</p>
      ),
    },
    {
      title: "Name",
      dataIndex: "council_name",
      key: "council_name",
      render: (_text, record) => (
        <p className="brand-name">{record.council_name || ""}</p>
      ),
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      render: (_text, record) => (
        <p className="brand-name">{record.website || ""}</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "contact_email_address",
      key: "contact_email_address",
      render: (_text, record) => (
        <p className="brand-name">{record.contact_email_address || ""}</p>
      ),
    },
    {
      title: "Contact number",
      dataIndex: "contact_number",
      key: "contact_number",
      render: (_text, record) => (
        <p className="brand-name">{record.contact_number || ""}</p>
      ),
    },

    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div className="btn-actions">
            <span onClick={() => this.handleEditCouncilInfo(record)}>Edit</span>
          </div>
        );
      },
    },
  ];

  render() {
    const { councilTaxInfo } = this.props;
    const { editCouncilModal, selectedCouncilData } = this.state;
    let results = [];

    if (councilTaxInfo && councilTaxInfo.length > 0) {
      for (var i = 0; i < councilTaxInfo.length; i++) {
        var key = `council-tax-${councilTaxInfo[i].app_council_information_id}`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...councilTaxInfo[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Council Tax</h4>
            <Search
              placeholder="Search..."
              onSearch={this.onSearch}
              style={{
                width: 400,
                paddingLeft: 10,
              }}
              enterButton
              size="large"
            />
          </Col>
        </Row>
        <Row>
          <Table
            sortOrder={false}
            dataSource={results}
            columns={this.columns}
            pagination={false}
          />
        </Row>

        {editCouncilModal && selectedCouncilData && (
          <CustomModal
            className={"signup-setting-modal"}
            visible={editCouncilModal}
            onClose={this.handleEditCouncilInfo}
          >
            <Form
              size="large"
              // initialValues={selectedCouncilData}
              onFinish={(val) => this.submitForm(val)}
              layout="vertical"
              className="form-login"
            >
              <p className="txt-title">Edit council tax info</p>
              <div className="custom-form-container">
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter merchant name",
                    },
                  ]}
                  initialValue={selectedCouncilData?.council_name || ""}
                >
                  <Input
                    placeholder="Council name"
                    type="text"
                    value={selectedCouncilData?.council_name || ""}
                    disabled={true}
                  />
                </Form.Item>

                <Form.Item
                  name="alternate_name"
                  label="Aliases(comma separated)"
                  initialValue={selectedCouncilData?.alternate_name || ""}
                >
                  <Input
                    placeholder="Aliases..."
                    type="text"
                    value={selectedCouncilData.alternate_name || ""}
                  />
                </Form.Item>
              </div>

              <Button
                className="text-gray"
                htmlType="submit"
                type="primary"
                block
              >
                Save
              </Button>
            </Form>
          </CustomModal>
        )}
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    councilTaxInfo: state.councilTaxInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
