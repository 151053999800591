import { Button, Col, Row, Form, Input, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./index.less";
import moment from "moment";
const { Search } = Input;

// It's shows the card catalog info
class index extends Component {
  state = {
    // editCouncilModal: false,
    // selectedCouncilData: null,
    // selectedCouncilId: null,
    // selectedCouncil: null,
    // councilInfoModal: false,
  };

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { userId } = params;
    this.props.getUserGiftLogs(userId);
  }

  // Render the card info in table formate
  columns = [
    {
      title: "ID",
      dataIndex: "user_coin_gift_log_id",
      key: "user_coin_gift_log_id",
      render: (_text, record) => (
        <p className="brand-name">{record.sr_no || ""}</p>
      ),
    },
    {
      title: "Name",
      dataIndex: "user_email",
      key: "user_email",
      render: (_text, record) => (
        <p className="brand-name">{record.user_email || ""}</p>
      ),
    },
    {
      title: "Gift Action",
      dataIndex: "action",
      key: "action",
      render: (_text, record) => (
        <p className="brand-name">{record.action || ""}</p>
      ),
    },
    {
      title: "Coins gifted",
      dataIndex: "coin_amount",
      key: "coin_amount",
      render: (_text, record) => (
        <p className="brand-name">{record.coin_amount || ""}</p>
      ),
    },
    {
      title: "Date created",
      dataIndex: "date_created",
      key: "date_created",
      render: (_text, record) => (
        <p className="brand-name">
          {moment(record.date_created, "YYYY-MM-DD HH:mm:ss").format(
            "MM-DD-YYYY"
          ) || ""}
        </p>
      ),
    },
    {
      title: "Bill Info",
      dataIndex: "bill_info",
      key: "bill_info",
      render: (_text, record) => (
        <p className="brand-name">{record?.bill_info || ""}</p>
      ),
    },
    // {
    //   title: "Date created",
    //   dataIndex: "date_created",
    //   key: "date_created",
    //   render: (_text, record) => (
    //     <p className="brand-name">
    //       {moment(record.date_created, "YYYY-MM-DD HH:mm:ss").format(
    //         "MM-DD-YYYY"
    //       ) || ""}
    //     </p>
    //   ),
    // },

    // {
    //   title: "Actions",
    //   key: "action",
    //   render: (_text, record) => {
    //     return (
    //       <div className="btn-actions">
    //         <span onClick={() => this.handleEditCouncilInfo(record)}>Edit</span>
    //       </div>
    //     );
    //   },
    // },
  ];

  render() {
    const { userGiftLogsList } = this.props;
    let results = [];
    if (userGiftLogsList && userGiftLogsList.length > 0) {
      for (var i = 0; i < userGiftLogsList.length; i++) {
        var key = `user-gift-logs-${userGiftLogsList[i].user_coin_gift_log_id}`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...userGiftLogsList[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Coin Gift Logs</h4>
          </Col>
        </Row>
        <Row>
          <Table
            sortOrder={false}
            dataSource={results}
            columns={this.columns}
            pagination={false}
          />
        </Row>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    userGiftLogsList: state.userGiftLogsList,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
