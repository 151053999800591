import { Button, Col, Row, Form, Input, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./subcode-used.less";
import moment from "moment";

// It's shows the card catalog info
class subcodesUsed extends Component {
  state = {
    deleteCardModal: false,
    pauseCardModal: false,
    editCardModal: false,
    addCardModal: false,
    selectedCardId: null,
    selectedCardData: null,
    selectedFile: null,
    selectedImageSrc: null,
    isPause: null,
    action: "",
  };

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.props.loadCardCatalog();

    this.props.getUsedSubCode(id);
  }

  // Open the edit card pop up
  handleEditCardModal = (cardId, data, evt) => {
    const { editCardModal } = this.state;

    if (data && data?.brand_name === "") {
      data.brand_name = data.sr_brand_name;
    }

    this.setState({
      editCardModal: !editCardModal,
      selectedFile: null,
      selectedImageSrc: null,
      selectedCardId: cardId,
      selectedCardData: data,
    });
  };

  // Open delete card pop up

  handleDeleteCardModal = (cardId) => {
    const { deleteCardModal } = this.state;

    this.setState({
      deleteCardModal: !deleteCardModal,
      selectedCardId: cardId || null,
      action: "delete",
    });
  };

  handlePauseCardModal = (cardId, action) => {
    const { pauseCardModal } = this.state;
    this.setState({
      pauseCardModal: !pauseCardModal,
      selectedCardId: cardId || null,
      // isPause: isUsed === null ? null : isUsed,
      action: action,
    });
  };

  // Open add card pop up
  handleAddCardModal = () => {
    const { addCardModal } = this.state;
    this.setState({
      addCardModal: !addCardModal,
    });
  };

  //Delete Subcode
  submitForm = (action) => {
    const { selectedCardId } = this.state;
    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    if (action === "delete") {
      this.props
        .updateUserAction(selectedCardId, { action: "delete" })
        .then((res) => {
          if (res && res.data && res.data.status) {
            notifySuccess(res?.data?.message || "Done");
            this.setState({
              ...this.state,
              deleteCardModal: false,
              selectedCardId: null,
              action: "",
            });
            this.props.getUsedSubCode(id);
          } else {
            var errorMessage =
              res?.data?.message || res?.err?.message || "Something went wrong";
            notifyError(errorMessage);
          }
        });
      return;
    }

    if (action === "Pause" || action === "Unpause") {
      this.props
        .updateUserAction(selectedCardId, { action: action })
        .then((res) => {
          if (res && res.data && res.data.status) {
            notifySuccess(res?.data?.message || "Done");
            this.setState({
              ...this.state,
              pauseCardModal: false,
              selectedCardId: null,
              action: "",
            });
            this.props.getUsedSubCode(id);
          } else {
            var errorMessage =
              res?.data?.message || res?.err?.message || "Something went wrong";
            notifyError(errorMessage);
          }
        });
      return;
    }
  };

  handleFile = (event) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      this.setState({
        selectedImageSrc: [reader.result],
      });
    }.bind(this);
    this.setState({ selectedFile: file });
  };

  convertToCSV = (jsonArray) => {
    const headers = Object.keys(jsonArray[0]);
    const csvRows = [];

    // Add the header row
    csvRows.push(headers.join(","));

    // Add the data rows
    for (const row of jsonArray) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '""'); // Escape double quotes
        return `"${escaped}"`; // Wrap in quotes
      });
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };

  downloadCSV = (fileName, jsonData = []) => {
    if (jsonData?.length) {
      const csvContent = this.convertToCSV(jsonData);
      // console.log("csvContent", csvContent);

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  // Render the card info in table formate
  columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: "13px" }}>
          {record.sr_no || ""}
        </p>
      ),
    },

    {
      title: "Email",
      dataIndex: "u_email_id",
      key: "u_email_id",
      render: (_text, record) => (
        <p className="brand-name" style={{ width: "230px" }}>
          {record.u_email_id || ""}
        </p>
      ),
    },

    {
      title: "Verified",
      dataIndex: "is_email_verified",
      key: "is_email_verified",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: "10px" }}>
          {record.is_email_verified ? "Yes" : "No"}
        </p>
      ),
    },

    {
      title: "Date Joined",
      dataIndex: "date_created",
      key: "date_created",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: "6px" }}>
          {moment(record.date_created).format("DD/MM/YY") || ""}
        </p>
      ),
    },

    {
      title: "Last Active",
      dataIndex: "last_login_date",
      key: "last_login_date",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: "6px" }}>
          {record.last_login_date
            ? moment(record.last_login_date).format("DD/MM/YY")
            : "NA"}
        </p>
      ),
    },

    {
      title: "User Number",
      dataIndex: "user_unique_id",
      key: "user_unique_id",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: "10px" }}>
          {"#" + record.user_unique_id || ""}
        </p>
      ),
    },

    {
      title: "Subcode Used",
      dataIndex: "subcode_used",
      key: "subcode_used",
      render: (_text, record) => (
        <p className="brand-name">{record.subcode_used || "--"}</p>
      ),
    },
    {
      title: "Master code Used",
      dataIndex: "master_code_used",
      key: "master_code_used",
      render: (_text, record) => (
        <p className="brand-name">
          {record.master_code_used == 1 ? "Yes" : "No"}
        </p>
      ),
    },

    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        var action = record.status === "active" ? "Pause" : "Unpause";
        return (
          <div className="btn-actions">
            <span
              style={{
                marginRight: 16,
              }}
              onClick={(e) => this.handleDeleteCardModal(record.user_id)}
            >
              Delete
            </span>

            <span
              style={{
                marginRight: 16,
              }}
              onClick={(e) => this.handlePauseCardModal(record.user_id, action)}
            >
              {action}
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { match, subCodeUsedList } = this.props;

    const { params } = match;
    const { partnerName, totalCount } = params;
    const {
      addCardModal,
      editCardModal,
      deleteCardModal,
      selectedImageSrc,
      selectedCardData,
      pauseCardModal,
      action,
    } = this.state;

    let results = [];

    if (subCodeUsedList && subCodeUsedList.length !== 0) {
      for (var i = 0; i < subCodeUsedList.length; i++) {
        results.push({
          sr_no: i + 1,
          ...subCodeUsedList[i],
        });
      }
    }

    return (
      <BasicLayout>
        <div
          role="div"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1.5rem 3rem 0 3rem",
          }}
        >
          <div md={24} role="div">
            <h4>
              {partnerName} -Subcodes used ({results.length})
            </h4>
          </div>
          <div md={23} role="div">
            <div>
              <Button
                // className="btn-add-subcodeUSED"
                style={{ color: "black" }}
                type="primary"
                onClick={() =>
                  this.downloadCSV(
                    `${partnerName}_${moment().format(
                      "DD-MM-YYYY_HH:mm:ss"
                    )}.csv`,
                    results.map((item) => ({
                      sr_no: item.sr_no,
                      user_email: item.u_email_id || "-",
                      used_subcode: item?.subcode_used || null,
                    }))
                  )
                }
              >
                Export
              </Button>
            </div>
          </div>
        </div>

        <Row
          className="card-table"
          style={{
            minHeight: "70vh",
          }}
        >
          <Table
            sortOrder={false}
            dataSource={results}
            columns={this.columns}
          />
        </Row>

        <CustomModal
          className={"signup-setting-modal"}
          visible={deleteCardModal}
          onClose={this.handleDeleteCardModal}
        >
          <div className="txt-title">Delete this User?</div>
          <span className="txt-info">
            This change cannot be undone. All their content will be removed from
            SuperFi
          </span>

          <Button
            type="secondary"
            block
            onClick={() => this.submitForm("delete", this.state.selectedCardId)}
          >
            Delete
          </Button>
        </CustomModal>

        <CustomModal
          className={"signup-setting-modal"}
          visible={pauseCardModal}
          onClose={() => this.handlePauseCardModal(null, null)}
        >
          <p className="txt-title">{action} this parnter</p>
          {action === "Pause" ? (
            <p className="txt-info">
              They will not be able to sign into their SuperFi account
            </p>
          ) : (
            <p className="txt-info">
              They will be able to sign into their SuperFi account
            </p>
          )}

          <Button
            type="primary"
            block
            onClick={() => this.submitForm(action, this.state.selectedCardId)}
          >
            {action}
          </Button>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    subCodeUsedList: state.subCodeUsedList,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(subcodesUsed));
